// 添加路由模块名称前缀
export const getModuleName = (moduleName, routes) => {
    moduleName = typeof(moduleName) == 'string' ? moduleName : '';
    Array.isArray(routes) && routes.map(item => {
      if (!item?.path && !item?.name) {
        alert(`路由模块为${moduleName}的实例有误，请检查格式是否正确`);
        return item;
      } else {
        item.path = `/${moduleName}${item.path.indexOf('/') == -1 ? '/' : ''}${item.path}`;
        item.name = `${moduleName}${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`;
        return item;
      }
    })
    return routes;
  }
