import { getModuleName } from '../config';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/initRemark/login.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/initRemark/index.vue')
  }
]

export default getModuleName('remark', routes);