import { getModuleName } from '../config';
const routes = [
  {
    path: '/tenChallenge/:id',
    name: 'TenChallenge',
    component: () => import('@/views/littleGameGroup/tenChallenge/tenChallenge.vue')
  },
  {
    path: '/tenChallenge:state',
    name: 'TenChallengeState',
    component: () => import('@/views/littleGameGroup/tenChallenge/tenChallengeState.vue')
  },
  {
    path: '/index/:id',
    name: 'tenChallenge',
    component: () => import('@/views/littleGameGroup/index.vue')
  },
]

export default getModuleName('littleGameGroup', routes);