import { createRouter, createWebHistory } from 'vue-router';
import otherRemarkRoutes from './other/remark';
import activityMarketScreenRoutes from './activity/marketScreen';
import littleGameOneRoutes from './activity/littleGameOne'; // 十秒挑战
import questions from './activity/questions'; //红包雨
import slamDunk from './activity/SLAMDUNK'; //红包雨


export default createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', redirect: '/market/birthdayImg' },
    ...otherRemarkRoutes, // remark模块，用于记录架构图
    ...activityMarketScreenRoutes,
    ...littleGameOneRoutes,
    ...questions,
    ...slamDunk
  ]
})