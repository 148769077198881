<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>

<style>
html, body{
  margin: 0;
  padding: 0;
  min-width: 100vw;
  min-height: 100vh;
}
#app {
  color: #2c3e50;
  height: 100vh;
}
.liangfen{
  position: absolute;
  width: 100%;
}
</style>
