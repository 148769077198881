import {
    // ElPagination,
    // ElDialog,
    // ElAutocomplete,
    // ElDropdown,
    // ElDropdownMenu,
    // ElDropdownItem,
    // ElMenu,
    // ElSubmenu,
    // ElMenuItem,
    // ElMenuItemGroup,
    ElInput,
    // ElInputNumber,
    // ElRadio,
    // ElRadioGroup,
    // ElRadioButton,
    // ElCheckbox,
    // ElCheckboxButton,
    // ElCheckboxGroup,
    // ElSwitch,
    // ElSelect,
    // ElOption,
    // ElOptionGroup,
    ElButton,
    // ElButtonGroup,
    // ElTable,
    // ElTableColumn,
    // ElDatePicker,
    // ElTimeSelect,
    // ElTimePicker,
    // ElPopover,
    // ElTooltip,
    // ElBreadcrumb,
    // ElBreadcrumbItem,
    ElForm,
    ElFormItem,
    // ElTabs,
    // ElTabPane,
    // ElTag,
    // ElTree,
    // ElAlert,
    // ElSlider,
    // ElIcon,
    ElRow,
    ElCol,
    // ElUpload,
    // ElProgress,
    // ElSpinner,
    // ElBadge,
    // ElCard,
    // ElRate,
    // ElSteps,
    // ElStep,
    ElCarousel,
    ElCarouselItem,
    // ElCollapse,
    // ElCollapseItem,
    // ElCascader,
    // ElColorPicker,
    // ElTransfer,
    // ElContainer,
    // ElHeader,
    // ElAside,
    // ElMain,
    // ElFooter,
    // ElTimeline,
    // ElTimelineItem,
    // ElLink,
    // ElDivider,
    ElImage,
    // ElCalendar,
    // ElBacktop,
    // ElPageHeader,
    // ElCascaderPanel,
    ElLoading,
    // ElMessageBox,
    // ElMessage,
    // ElNotification,
    // ElDrawer,
    // ElPopConfirm
} from 'element-plus';
import 'element-plus/theme-chalk/index.css';

const [components, plugins] = [
    [
        // ElPagination,
        // ElAutocomplete,
        // ElDropdown,
        // ElDropdownMenu,
        // ElDropdownItem,
        // ElMenu,
        // ElSubmenu,
        // ElMenuItem,
        // ElMenuItemGroup,
        ElInput,
        // ElInputNumber,
        // ElRadio,
        // ElRadioGroup,
        // ElRadioButton,
        // ElCheckbox,
        // ElCheckboxButton,
        // ElCheckboxGroup,
        // ElSwitch,
        // ElSelect,
        // ElOption,
        // ElOptionGroup,
        ElButton,
        // ElButtonGroup,
        // ElTable,
        // ElTableColumn,
        // ElDatePicker,
        // ElTimeSelect,
        // ElTimePicker,
        // ElBreadcrumb,
        // ElBreadcrumbItem,
        ElForm,
        ElFormItem,
        // ElTabs,
        // ElTabPane,
        // ElTag,
        // ElTree,
        // ElSlider,
        // ElIcon,
        ElRow,
        ElCol,
        // ElUpload,
        // ElProgress,
        // ElSpinner,
        // ElBadge,
        // ElCard,
        // ElRate,
        // ElSteps,
        // ElStep,
        ElCarousel,
        ElCarouselItem,
        // ElCollapse,
        // ElCollapseItem,
        // ElCascader,
        // ElColorPicker,
        // ElTransfer,
        // ElContainer,
        // ElHeader,
        // ElAside,
        // ElMain,
        // ElFooter,
        // ElTimeline,
        // ElTimelineItem,
        // ElLink,
        // ElDivider,
        ElImage,
        // ElCalendar,
        // ElBacktop,
        // ElPageHeader,
        // ElCascaderPanel
    ], [
        // ElAlert,
        // ElDialog,
         ElLoading,
        // ElMessage,
        // ElMessageBox,
        // ElNotification,
        // ElPopover,
        // ElTooltip,
        // ElDrawer,
        // ElPopConfirm
    ]
]

export default (Vue) => {
    components.forEach(component => {
       Vue.component(component.name, component)
    });
    plugins.forEach(plugin => {
        Vue.use(plugin)
    })
};




