
const routes = [
    {
        path: '/slamDunk',
        name: 'slamDunk',
        component: () => import('@/views/slamDunk/index.vue')
    },
    {
        path: '/index/:id',
        name: 'index',
        component: () => import('@/views/index.vue')
    },

]

export default routes