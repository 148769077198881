import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import ElementPlus from './config/element.config.js' // 按需引入
import animated from 'animate.css'
import vueSeamlessScroll from 'vue-seamless-scroll'
import infiniteScroll from 'vue-infinite-scroll'
const Vue = createApp(App)
ElementPlus(Vue)
Vue.use(vueSeamlessScroll)
Vue.use(infiniteScroll)
Vue.use(animated)
Vue.use(router)
Vue.use(store)
Vue.mount('#app')

