import { getModuleName } from '../config';
const routes = [
  {
    path: '/leaveIndex',
    name: 'LeaveIndex',
    component: () => import('@/views/marketMessage/leaveIndex.vue')
  },
  {
    path: '/birthdayImg',
    name: 'BirthdayImg',
    component: () => import('@/views/marketMessage/birthdayImg.vue')
  },
  {
    path: '/lotteryDraw',
    name: 'LotteryDraw',
    component: () => import('@/views/marketMessage/lotteryDraw.vue')
  },
  {
    path: '/transform',
    name: 'Transform',
    component: () => import('@/views/marketMessage/transform.vue')
  },
  {
    path: '/streetView',
    name: 'StreetView',
    component: () => import('@/views/marketMessage/streetView.vue')
  }
]

export default getModuleName('market', routes);
